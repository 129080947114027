let config = {
  // default country set
  DefaultCountrySelectedInMobile: "ca",
  // default language
  DefaultLanguage: "en",

  //google Places key
  YOUR_GOOGLE_MAPS_API_KEY: "AIzaSyCYrdxhfvs0X1l3IxZu5jwAxIZyQr2cUN0",
};

export default config;
